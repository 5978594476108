import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import SEO from "../components/seo"
import { Image, Text } from "../components/Core"
import { Office, Hours, Phone } from "../components/locations"
import { Heading } from "../components/Hero"
import { Section, Columns } from "../components/Containers"
import useForms from "../hooks/useForms"

import { getPostObj } from "../utils/utils"

const ContactUsPage = ({ pageContext, location, data }) => {
  const { post, language } = getPostObj(
    pageContext,
    data.allUniquePagesJson.nodes[0],
    data.allSpanishUniquePagesJson.nodes[0]
  )
  const translations = data.translations.nodes[0]

  const { form } = useForms(post.formId)

  const locations = data.allLocationsJson.nodes

  return (
    <Layout
      className={`contact-us`}
      layoutClass="contact-fab"
      language={language}>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={language}
      />
      <Heading heading={post.heading} />

      <Image
        className="splash"
        publicId={post.googleMapsBackground.splashPublicId}
        useAR
      />

      <Section>
        <Columns sideColumnsSize={2}>
          <div className="column">
            <Text as="h2" className="mt-0" text={post.contactBody.heading} />
            <Text text={post.contactBody.blurb} />
            {/* <Seamless className="mt-3" src={form.embedUrl} /> */}
          </div>
          <div className="column is-1 is-hidden-touch"></div>
          <div className="column is-6 is-hidden-touch">
            <div className="contact-us__sidebar">
              <Text as="h5" text={translations.address[language]} />
              {locations.map((local, i) => (
                <div className="contact-us__sidebar-address" key={i}>
                  <p className="bold">{`${local.locationData.locationName} Office`}</p>
                  <Office
                    city={local.locationData.cityName}
                    state={local.locationData.stateAbbr}
                    zip={local.locationData.cityZip}
                    streetName={local.locationData.streetName}
                    streetNumber={local.locationData.streetNumber}
                    gmb={local.locationData.googlePlaceLink}
                  />
                </div>
              ))}

              <Text as="h5" text={translations.hours[language]} />
              <Hours locationHours={locations[0].locationData.locationHours} />

              <Text as="h5" text={translations.phone[language]} />
              {/* <Phone phoneNumber={locations[0].locationData.phone} /> */}
              {locations.map((l, i) => (
                <p key={i} className="my-0">
                  <Phone phoneNumber={l.locationData.phone} />
                </p>
              ))}
            </div>
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query contactUsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        contactBody {
          heading
          blurb
        }
        formId
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
        }
      }
    }

    allSpanishUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        title
        heading
        metaTitle
        metaDescription
        contactBody {
          heading
          blurb
        }
        googleMapsBackground {
          pinLink
          pinTextLineOne
          pinTextLineTwo
          splashPublicId
        }
      }
    }
    allLocationsJson(
      filter: { useOnFooter: { eq: true } }
      sort: { fields: locationData___order }
    ) {
      nodes {
        locationData {
          ...locationDataFields
        }
        pageData {
          ...locationPageURL
          layout_3 {
            formSection {
              hasForm
              seamlessShortcode
            }
          }
        }
      }
    }
    forms: allDataJson(filter: { optionsItem: { eq: "seamless" } }) {
      nodes {
        allSeamlessForms {
          embedUrl
          seamlessShortcode
          title
        }
      }
    }
    translations: allTranslationsJson {
      nodes {
        address {
          en
          es
        }
        hours {
          en
          es
        }
        phone {
          en
          es
        }
      }
    }
  }
`

export default ContactUsPage
